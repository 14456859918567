import { createTheme } from "@fluentui/react";
import { SharedColors } from "@fluentui/theme";
import { NeutralColors } from "@fluentui/theme";

export const theme = createTheme({
  palette: {
    //From FluentUI Theme Palette
    themePrimary: SharedColors.cyan10,
    themeDark: SharedColors.cyan30, //Checked
    themeDarkAlt: SharedColors.cyan20, //Hover
    // neutralLighter: 'pink', //Hover of default element

    //Our colors
    errorMessage: SharedColors.magentaPink10,
    errorMessageHover: SharedColors.magentaPink20,
    headerColor: NeutralColors.gray110,
    headerTextColor: NeutralColors.gray10,
    mainBackgroundColor: NeutralColors.gray10,
    white: NeutralColors.white,
    red: SharedColors.red10,
    borderColor: NeutralColors.gray30,
  },

  // defaultFontStyle: {
  //   fontFamily: "Monaco, Menlo, Consolas",
  //   fontWeight: "regular",
  // },

  fonts: {
    small: {
      fontSize: "11px",
    },
    medium: {
      fontSize: "14px",
    },
    mediumSemiBold: {
      fontSize: "14px",
      fontWeight: "600",
    },
    large: {
      fontSize: "20px",
    },
    largeSemi: {
      fontSize: "20px",
      fontWeight: "600",
    },
    largeBold: {
      fontSize: "20px",
      fontWeight: "bold",
    },
    xLarge: {
      fontSize: "22px",
      fontWeight: "600",
    },
  },
});
