import React from "react";
import { theme } from "../../styles/Theme";
import { mergeStyleSets, Stack } from "@fluentui/react";
import HorizontalNavigation from "./HorizontalNavigation";
import MeButton from "../Buttons/MeButton";
import TyrensLogo from "../TyrensLogo";

const stackClasses = mergeStyleSets({
  header: {
    backgroundColor: theme.palette.headerColor,
    color: theme.palette.headerTextColor,
    height: 48,
  },
  outerStack: {
    alignItems: "center",
  },
  innerStack: {
    alignItems: "center",
  },
  title: {
    fontWeight: "600",
    fontSize: "16px",
  },
});

const tokens = {
  childrenGap: 20,
};

export default function AppHeader({ logo, title, links, me }) {
  return (
    <header className={stackClasses.header}>
      <Stack
        horizontal
        horizontalAlign="space-between"
        className={stackClasses.outerStack}
      >
        <Stack.Item>
          <Stack horizontal className={stackClasses.innerStack} tokens={tokens}>
            <Stack.Item>
              {logo || <TyrensLogo style={{ margin: "4px 0 0 .5rem" }} />}
            </Stack.Item>
            <Stack.Item>
              <span className={stackClasses.title}>{title}</span>
            </Stack.Item>
            <Stack.Item>
              <HorizontalNavigation links={links} />
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Stack horizontal className={stackClasses.innerStack}>
            <Stack.Item>{me || <MeButton />}</Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </header>
  );
}
