import { mergeStyleSets } from '@fluentui/react'
import { theme } from '../styles/Theme'

const TyrensLogo = ({ width, height, primaryColor, secondaryColor, textColor, style }) => {
  const classes = mergeStyleSets({
    svg: {
      width: width || '7rem',
      height: height || '1.5rem',
    },
    primary: {
      fill: primaryColor || theme.palette.headerTextColor,
    },
    secondary: {
      fill: secondaryColor || theme.palette.headerTextColor,
    },
    text: {
      fill: textColor || theme.palette.headerTextColor,
    },
  })

  return (
    <svg viewBox="0 0 171 38" id="logo" className={classes.svg} style={style}>
      <path
        d="M11.215 36.769l.001-.001a4.088 4.088 0 0 0 5.689-.066c1.068-1.064 1.648-2.8 1.431-4.305-.204-1.791.391-2.652 1.023-3.273.624-.627 1.489-1.221 3.29-1.016 1.513.215 3.259-.362 4.328-1.425a4.034 4.034 0 0 0 .067-5.658h.001c-.002-.002-.004-.003-.005-.006l-.004-.004v.001a4.089 4.089 0 0 0-5.69.066c-1.069 1.064-1.647 2.8-1.432 4.307.205 1.79-.39 2.65-1.021 3.272-.623.626-1.49 1.22-3.29 1.015-1.512-.214-3.26.362-4.328 1.426a4.032 4.032 0 0 0-.068 5.657v.001a.008.008 0 0 1 .004.005l.004.004"
        className={classes.primary}
      ></path>
      <path
        d="M27.774 32.558c.558 2.172-.763 4.38-2.947 4.934-2.184.555-4.407-.758-4.963-2.929a4.059 4.059 0 0 1 2.948-4.937c2.183-.553 4.405.76 4.962 2.932m-10.46-5.46h-.001a4.085 4.085 0 0 1-5.688-.067c-1.07-1.063-1.648-2.8-1.43-4.305.203-1.791-.392-2.652-1.024-3.272-.625-.628-1.489-1.221-3.291-1.016-1.513.215-3.258-.362-4.327-1.425a4.035 4.035 0 0 1-.067-5.658v-.001l.005-.005a.015.015 0 0 0 .003-.004v.001a4.087 4.087 0 0 1 5.689.066c1.07 1.064 1.647 2.8 1.433 4.306-.206 1.791.39 2.651 1.02 3.273.625.626 1.49 1.22 3.292 1.015 1.512-.214 3.259.362 4.327 1.425a4.034 4.034 0 0 1 .068 5.658v.001a.02.02 0 0 0-.005.004c0 .002-.002.003-.004.005"
        className={classes.secondary}
      ></path>
      <path
        d="M.755 22.888C.2 25.06 1.52 27.27 3.703 27.823c2.183.554 4.406-.759 4.962-2.93a4.058 4.058 0 0 0-2.948-4.936c-2.183-.554-4.404.76-4.962 2.931m26.29-21.206h-.002a4.086 4.086 0 0 0-5.687.067c-1.07 1.064-1.65 2.8-1.433 4.305.205 1.792-.39 2.652-1.021 3.273-.626.627-1.49 1.22-3.292 1.016-1.513-.216-3.259.362-4.327 1.425a4.036 4.036 0 0 0-.068 5.658l.005.005.003.005.001-.001a4.089 4.089 0 0 0 5.689-.067c1.07-1.063 1.648-2.799 1.432-4.306-.205-1.79.391-2.65 1.022-3.273.624-.626 1.49-1.22 3.29-1.015 1.513.215 3.259-.361 4.328-1.425a4.033 4.033 0 0 0 .067-5.657l.001-.002a.008.008 0 0 1-.004-.004l-.005-.004"
        className={classes.primary}
      ></path>
      <path
        d="M10.485 5.892C9.928 3.72 11.25 1.51 13.433.958c2.184-.555 4.407.758 4.962 2.93a4.059 4.059 0 0 1-2.947 4.936c-2.184.553-4.405-.76-4.963-2.932m10.46 5.46h.001a4.086 4.086 0 0 1 5.688.066c1.07 1.064 1.65 2.8 1.432 4.306-.204 1.79.39 2.652 1.022 3.272.625.628 1.489 1.221 3.291 1.017 1.513-.216 3.26.361 4.328 1.424a4.034 4.034 0 0 1 .067 5.657l.001.001-.005.006-.004.004v-.001a4.088 4.088 0 0 1-5.69-.067c-1.069-1.063-1.647-2.798-1.431-4.305.204-1.791-.391-2.651-1.022-3.273-.624-.626-1.49-1.22-3.29-1.015-1.513.214-3.26-.362-4.329-1.426a4.033 4.033 0 0 1-.066-5.657l-.001-.001a.008.008 0 0 0 .004-.004l.004-.005"
        className={classes.secondary}
      ></path>
      <path
        d="M37.504 15.562c.557-2.172-.763-4.381-2.947-4.935-2.184-.554-4.407.759-4.962 2.93a4.059 4.059 0 0 0 2.947 4.936c2.184.554 4.404-.759 4.962-2.931"
        className={classes.primary}
      ></path>
      <path
        className={classes.text}
        d="M47.406 7.053v3.355h8.764v20.99h5.095v-20.99h6.359l-1.95-3.355zM107.133 17.128c.827-1.267 1.241-2.613 1.241-4.039 0-1.195-.284-2.266-.853-3.215A5.32 5.32 0 0 0 105.2 7.75c-.982-.465-2.41-.698-4.284-.698h-9.994v24.344h4.797V10.392h2.605c2 0 3.326.293 3.978.88.653.586.979 1.38.979 2.377 0 1.15-.4 2.182-1.204 3.092-.558.636-2.749 1.873-4.008 2.106v3.59l7.367 8.96h6.205L102.246 20.9c1.51-.67 4.06-2.506 4.887-3.772M117.733 20.541h8.748v-3.356h-8.748v-6.776h10.584V7.053h-15.678v24.344h16.455v-3.453h-11.361zM147.586 23.683l-11.32-16.63h-4.49v24.344h4.085v-16.63l11.241 16.63h4.568V7.053h-4.084zM169.615 21.009c-.745-1.107-2.469-2.413-5.173-3.916l-1.77-.971c-1.534-.856-2.493-1.53-2.875-2.025-.394-.494-.591-1.027-.591-1.598 0-.746.322-1.385.966-1.917.644-.532 1.581-.799 2.812-.799 1.49 0 3.499.466 6.029 1.4v-3.9c-2.472-.56-4.693-.839-6.666-.839-2.639 0-4.663.653-6.072 1.96-1.408 1.305-2.113 2.873-2.113 4.706 0 1.46.423 2.743 1.27 3.851.833 1.109 2.429 2.317 4.788 3.622l1.653.906c1.535.857 2.5 1.553 2.894 2.09.393.55.593 1.164.593 1.844 0 .89-.353 1.652-1.058 2.29-.702.635-1.77.954-3.2.954a16.09 16.09 0 0 1-2.608-.233c-.943-.155-2.372-.632-4.282-1.43v4.18c2.626.548 4.93.822 6.909.822 3.338 0 5.771-.707 7.301-2.12 1.53-1.414 2.295-3.118 2.295-5.113 0-1.392-.368-2.646-1.102-3.764M79.653 17.331L73.681 7.052h-5.632l8.22 14.146v10.199h5.092V21.166l8.551-14.114H85.88zM124.636 1.497h-4.383l-2.319 3.218h3.374z"
      ></path>
    </svg>
  )
}

export default TyrensLogo
