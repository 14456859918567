import { useMsal } from "@azure/msal-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getAccessTokenAsync } from "../api/msal";
import { AuthContext } from "../context/AuthContext";
import { hasRequiredRoles, ADMIN } from "../auth";
import { employeesEndpoint } from "../helpers/apiUrls";

export const EmployeesContext = createContext({});

export const EmployeesContextProvider = ({ children }) => {
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { instance } = useMsal();

  const { identity } = useContext(AuthContext);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchEmployees = async (signal) => {
      const accessToken = await getAccessTokenAsync(instance, identity, [
        process.env.REACT_APP_SCOPES,
      ]);

      const headers = new Headers();
      const bearer = `Bearer ${accessToken}`;

      headers.append("Authorization", bearer);

      const options = {
        method: "GET",
        headers,
      };

      if (signal) options.signal = signal;

      const url = employeesEndpoint;
      await fetch(url, options)
        .then((response) => response.json())
        .then((result) => {
          const employees = result.map((employee) => ({
            employeeNo: employee.employeeNo,
            name: employee.name,
            created: new Date(`${employee.createdDate}`).toLocaleDateString(
              "sv-SE"
            ),
            sent: new Date(employee.sendDate).toLocaleDateString("sv-SE"),
          }));
          setEmployees(employees);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    };
    if (hasRequiredRoles(identity, [ADMIN])) {
      fetchEmployees(abortController.signal);
    }

    return () => abortController?.abort();
  }, [identity, instance]);

  return (
    <EmployeesContext.Provider value={{ employees, setEmployees, isLoading }}>
      {children}
    </EmployeesContext.Provider>
  );
};
