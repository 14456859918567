import { useMsal } from "@azure/msal-react";
import { mergeStyleSets, Spinner, Stack, Text } from "@fluentui/react";
import React from "react";
import { useContext } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import {
  fetchEmployee,
  fetchEmployeeFromGraph,
  uploadApproval,
} from "../api/profileImagesHandler";
import { AuthContext } from "../context/AuthContext";
import { MessagesContext } from "../context/MessagesContext";
import { stackTokens } from "../styles/stackConstants";
import { theme } from "../styles/Theme";
import DisplayMessages from "../components/Messages/DisplayMessages";
import PageLayout from "../components/PageLayout";
import SelectionForm from "../components/SelectionForm";
import { useMemo } from "react";
import useMsGraphToken from "../hooks/useMsGraphToken";

const classes = mergeStyleSets({
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "25px",
  },
  card: {
    padding: "20px",
    background: theme.palette.white,
    border: `1px solid ${theme.palette.borderColor}`,
  },
});

export default function UserApprovalPage() {
  const { instance } = useMsal();
  const { identity } = useContext(AuthContext);
  const { messages, setMessages } = useContext(MessagesContext);

  const scopes = useMemo(() => [process.env.REACT_APP_SCOPES], [])
  const accessToken = useMsGraphToken(scopes)


  const [employee, setEmployee] = useState(null);
  const [isLoadingEmployee, setIsLoadingEmployee] = useState(false)
  const [employeeNoFromGraph, setEmployeeNoFromGraph] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(3); // Default option B
  const [isApproved, setIsApproved] = useState(null);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const options = [
    { key: "A", text: "Ja, jag godkänner", statusId: 2, value: true },
    { key: "B", text: "Nej, jag godkänner inte", statusId: 3, value: false },
  ];

  const addMessage = useCallback(
    (message) => {
      setMessages([...(messages || []), message]);
    },
    [messages, setMessages]
  );

  useEffect(() => {
    const abortController = new AbortController();

    const getEmployeeFromGraph = async (userId) => {
      await fetchEmployeeFromGraph(
        abortController.signal,
        instance,
        identity,
        userId
      ).then((result) => {
        if (result !== null) {
          setEmployeeNoFromGraph(result.employeeId);
        }
      });
    };

    if (identity) {
      const userId = identity.localAccountId;
      getEmployeeFromGraph(userId);
    }

    return () => abortController?.abort();
  }, [identity, instance]);

  useEffect(() => {
    const abortController = new AbortController();

    const getEmployee = async () => {
      setIsLoadingEmployee(true)
      await fetchEmployee(
        abortController.signal,
        accessToken,
        employeeNoFromGraph,
        (errorMessage) => {
          addMessage({
            type: "error",
            title: "Något gick fel när användaren skulle hämtas",
            description: `${errorMessage}`,
          });
        }
      ).then((employee) => {
        setEmployee(employee);
      })
      .finally(() => {
        setIsLoadingEmployee(false)
      });
    };

    if (employeeNoFromGraph) {
      getEmployee();
    }

    return () => abortController?.abort();
  }, [employeeNoFromGraph, addMessage, identity, instance, accessToken]);



  const onSelectionChange = (e, selection) => {
    console.log(selection)
    setSelectedStatus(selection.statusId);
    setIsApproved(selection.value);
  };

  const handleSave = async () => {
    setIsSaving(true);

    const abortController = new AbortController();

    await uploadApproval(
      abortController.signal,
      instance,
      identity,
      employeeNoFromGraph,
      selectedStatus,
      (error) => {
        addMessage({
          type: "error",
          title: `Något gick fel när godkännandet skickades.`,
          description: `${error}`,
        });
      }
    ).then((result) => {
      if (result !== null) {
        setIsCompleted(true);
      }
    });

    setIsSaving(false);
    return () => abortController?.abort();
  };

  const renderBasedOnApproval = () => {
    switch (employee.approvalStatusId) {
      case 1:
        return (
          //   Vi inväntar användarens godkännande att använda den nya bilden publikt
          <SelectionForm
            label={`Godkänner du, ${employee.name}, att din profilbild visas på www.tyrens.se?`}
            options={options}
            defaultSelectedKey={"B"}
            onChange={onSelectionChange}
            onSave={handleSave}
            btnText={"Spara"}
            isSaving={isSaving}
          />
        );
      case 2:
        // Användaren har godkänt att bild används publikt
        return (
          <Text variant="medium">
            Du, {employee.name}, har godkänt att din bild används publikt
          </Text>
        );
      case 3:
        // Användaren har avböjt att bild används publikt
        return (
          <Text variant="medium">
            Du, {employee.name}, har avböjt att din bild används publikt
          </Text>
        );
      default:
        return (
          <Text variant="medium">
          Du har ingenting att godkänna just nu.
        </Text>
        );
    }
  };

  if (isLoadingEmployee) {
    return (
      <PageLayout>
        <Spinner label="Sidan laddas in" />
      </PageLayout>
    );
  }

  if (!employee) {
    return (
      <PageLayout>
        <Spinner label="Förbereder" />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <Stack tokens={stackTokens} className={classes.card}>
        {isCompleted ? (
          <div>
            <h1 style={theme.fonts.xLarge}>Tack!</h1>
            {isApproved ? (
              <Text variant="medium">
                Din profilbild kommer att bli tillgänglig på www.tyrens.se
              </Text>
            ) : (
              <Text variant="medium">
                Vi kommer inte att visa din profilbild på www.tyrens.se
              </Text>
            )}
          </div>
        ) : (
          <div>
            <h1 style={theme.fonts.xLarge}>Profilbild</h1>
            {renderBasedOnApproval()}
          </div>
        )}
      </Stack>
      <DisplayMessages />
    </PageLayout>
  );
}
