import React from "react";
import "office-ui-fabric-core/dist/css/fabric.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import HomePage from "./pages/HomePage";
import UserDetailsPage from "./pages/UserDetailsPage";
import AppHeader from "./components/Navigation/AppHeader";
import AppLayout from "./components/AppLayout";
import { loadTheme } from "@fluentui/react";
import { theme } from "./styles/Theme";
import { ProtectedRoutes } from "./components/ProtectedRoutes";
import LoginPage from "./pages/LoginPage";
import UnauthorizedPage from "./pages/UnauthorizedPage";
import EditUserImagesPage from "./pages/EditUserImagesPage";
import { ADMIN } from "./auth";
import UserApprovalPage from "./pages/UserApprovalPage";
import DisplayMessages from "./components/Messages/DisplayMessages";

initializeIcons();
loadTheme(theme);

function App() {
  return (
    <BrowserRouter>
      <AppLayout
        header={
          <AppHeader
            title="Profilbildsverktyg"
            links={[
              {
                isProtected: true,
                roles: [ADMIN],
                to: "",
                name: "Hantera anställda",
              },
            ]}
          />
        }
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="unauthorized" element={<UnauthorizedPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route
            path="user/:employeeNo"
            element={
              <ProtectedRoutes roles={[ADMIN]}>
                <UserDetailsPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="user/:employeeNo/edit"
            element={
              <ProtectedRoutes roles={[ADMIN]}>
                <EditUserImagesPage />
              </ProtectedRoutes>
            }
          />
          <Route
            path="approval"
            element={
              <ProtectedRoutes>
                <UserApprovalPage />
              </ProtectedRoutes>
            }
            />
        </Routes>
      </AppLayout>
      <DisplayMessages />
    </BrowserRouter>
  );
}

export default App;
