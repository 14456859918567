import { createContext } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../config";
import { MsalProvider } from "@azure/msal-react";
import useIdentity from "../hooks/useIdentity";
import useProfile from "../hooks/useProfile";
import useProfilePhoto from "../hooks/useProfilePhoto";


const msalInstance = new PublicClientApplication(msalConfig);

export const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  const identity = useIdentity();
  const profile = useProfile();
  const profilePhoto = useProfilePhoto();
  return (
    <AuthContext.Provider value={{ identity, profile, profilePhoto }}>
      {children}
    </AuthContext.Provider>
  );
};

export const AuthProvider = ({ children }) => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthContextProvider>{children}</AuthContextProvider>
    </MsalProvider>
  );
};
