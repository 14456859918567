import React, { createContext, useState, useCallback } from "react";

export const MessagesContext = createContext({});

export const MessagesContextProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const dismissMessage = useCallback((message) => {
    setMessages(current => {
      const next = current.filter(m => JSON.stringify(m) !== JSON.stringify(message))
      return next
    })
  }, [])

  const addMessage = useCallback((message) => {
    setMessages(current => {
      const next = [ ...current ]
      next.push(message)
      return next
    })
  }, [])

  return (
    <MessagesContext.Provider value={{ messages, addMessage, dismissMessage }}>
      {children}
    </MessagesContext.Provider>
  );
};
