import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { hasRequiredRoles } from "../auth";
import { AuthContext } from "../context/AuthContext";  
  
export const ProtectedRoutes = ({ roles, children }) => {
    const location = useLocation()
    const { identity } = useContext(AuthContext);
    let loginUrl = "/login?returnUrl=" + location.pathname
    if (!identity) return <Navigate to={loginUrl} replace />;
    const isAuthorized = true;
    
    if (roles) {
        hasRequiredRoles(identity, roles)
    }

    if (roles && !isAuthorized)
        return <Navigate to="/unauthorized" replace />;

    if (children) return <>{children}</>;
    return <Outlet />;
};

