import { mergeStyleSets } from "@fluentui/react";
import React from "react";
import { theme } from "../styles/Theme";

export default function ImageWithFocalPoint({
  setFocalPoint,
  focalPoint,
  imgSrc,
}) {
  const classes = mergeStyleSets({
    focalPoint: {
      position: "absolute",
      background: theme.palette.red,
      width: "20px",
      height: "20px",
      left: `${focalPoint[0] * 100}%`,
      top: `${focalPoint[1] * 100}%`,
      transform: "translate(-50%, -50%)",
      borderRadius: "50%",
      boxShadow: "0 0 0 5px white",
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
    },
    imageWrapper: {
      position: "relative",
    },
  });

  const handleImageClick = (e) => {
    //The nativeEvent will give us the X/Y position of the click in position to the image top left corner.
    // We divide that with the image width to get the position in percentage.
    const focalPointX =
      e.nativeEvent.offsetX / e.nativeEvent.target.clientWidth;
    const focalPointY =
      e.nativeEvent.offsetY / e.nativeEvent.target.clientHeight;
    setFocalPoint([focalPointX, focalPointY]);
  };

  return (
    <div className={classes.imageWrapper}>
      <img
        src={imgSrc}
        alt={imgSrc}
        onClick={handleImageClick}
        style={{ width: "100%" }}
      />
      {focalPoint && <div className={classes.focalPoint}></div>}
    </div>
  );
}
