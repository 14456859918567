import { useMsal } from '@azure/msal-react'
import { useEffect } from 'react';
import { useState } from 'react'
import { msalConfig } from '../config';
import useIdentity from './useIdentity';

const useMsGraphToken = (scopes = msalConfig.scopes) => {
  const { instance } = useMsal()
  const identity = useIdentity()
  const [token, setToken] = useState(null)

  useEffect(() => {
    if (!instance || !identity) return;
    (async () => {
        const request = {
            account: identity,
            scopes,
        };
        let accessToken = null;
        try {
            accessToken = await instance
            .acquireTokenSilent(request)
            .then((response) => response.accessToken);
        } catch (e) {
            accessToken = await instance
            .acquireTokenRedirect(request)
            .then((response) => response.accessToken);
        }
        setToken(accessToken);
    })()
  }, [instance, identity, scopes])

  return token
}
export default useMsGraphToken
