import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MessagesContext } from "../../context/MessagesContext";
import ErrorMessage from "./ErrorMessage";

export default function DisplayMessages() {
  const { messages, dismissMessage } = useContext(MessagesContext);
  const [current, setCurrent] = useState(null)

  useEffect(() => {
    if (!Array.isArray(messages) || messages.length === 0) {
      setCurrent(null)
    } else {
      setCurrent(messages[0])
    }
  }, [messages])

  const onRequestClose = () => {
    dismissMessage(current)
  };

  switch (current?.type) {
    case "error":
      return <ErrorMessage message={current} onRequestClose={onRequestClose} isOpen={true} />;
    default:
      return null;
  }
}
