import { MessageBar, MessageBarType, Stack, StackItem } from "@fluentui/react";
import { theme } from "../styles/Theme";
import { stackTokensLarge } from "../styles/stackConstants";
import { SignInButton } from "../components/Buttons/SignInButton";
import PageLayout from "../components/PageLayout";
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

const LoginPage = () => {
  const { identity } = useContext(AuthContext);
  const [searchParams] = useSearchParams()
  const returnUrl = searchParams.get("returnUrl")

  if(identity && returnUrl) {
    return <Navigate to={returnUrl} replace />;
  }
  
  return (
    <PageLayout>
      <Stack tokens={stackTokensLarge}>
        {identity ? (
          <MessageBar
            isMultiline={false}
            messageBarType={MessageBarType.success}
          >
            Du är nu inloggad och kan gå vidare till sidan du vill besöka
          </MessageBar>
        ) : (
          <MessageBar
            isMultiline={false}
            messageBarType={MessageBarType.warning}
          >
            Du är inte inloggad, logga in via knappen nedan
          </MessageBar>
        )}

        {!identity && (
          <StackItem>
            <SignInButton
              backgroundColor={theme.palette.themePrimary}
              backgroundColorHover={theme.palette.themeDarkAlt}
            />
          </StackItem>
        )}
      </Stack>
    </PageLayout>
  );
};

export default LoginPage;
