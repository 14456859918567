import { useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import { fetchEmployee } from "../api/profileImagesHandler";
import { MessagesContext } from "../context/MessagesContext";
import useMsGraphToken from "./useMsGraphToken";

const useEmployee = (employeeNo) => {
    const scopes = useMemo(() => [process.env.REACT_APP_SCOPES], [])
    const accessToken = useMsGraphToken(scopes)
    const [employee, setEmployee] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const {addMessage} = useContext(MessagesContext)

    useEffect(() => {
      if (!accessToken) return;
      const abortController = new AbortController();
      setIsLoading(true)
      fetchEmployee (
          abortController.signal,
          accessToken,
          employeeNo
      ).then((result) => {
          setEmployee(result)
      }).catch((e) => {
        addMessage({
            type: "error",
            title: "Unable to load Employee",
            description: e.message
        })
      }).finally(() => {
        setIsLoading(false)
      })
      return () => abortController.abort()
    }, [employeeNo, accessToken, addMessage])

    return [employee, isLoading]
}

export default useEmployee