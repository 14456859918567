import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import { originalProfileImageEndpoint } from "../helpers/apiUrls";
import { MessagesContext } from "../context/MessagesContext";
import useMsGraphToken from "./useMsGraphToken";

const useOriginalImage = (employeeNo) => {
    const scopes = useMemo(() => [process.env.REACT_APP_SCOPES], [])
    const accessToken = useMsGraphToken(scopes)
    const [originalImageUrl, setOriginalImageUrl] = useState(null)
    const [originalImageFile, setOriginalImageFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const {addMessage} = useContext(MessagesContext)

    const [totalNumberOfBytes, setTotalNumberOfBytes] = useState(0);
    const [numberOfBytesReceived, setNumberOfBytesReceived] = useState(0);
    const [percentReceived, setPercentReceived] = useState(0);

    useEffect(() => {
      if(!accessToken) return;
      const controller = new AbortController();

      (async () => {
        setIsLoading(true)
        try {
          const src = originalProfileImageEndpoint(employeeNo);
          const requestHeaders = new Headers();
          requestHeaders.append("Authorization", `Bearer ${accessToken}`);
        
          const options = {
            method: "GET",
            signal: controller.signal,
            headers: requestHeaders,
          };

          const response = await fetch(src, options);
          if (!response.ok) {
            if(response.status === 404) return null
            throw new Error("Ett fel uppstod när Originalbilden skulle hämtas")
          }
          const responseHeaders = {
            contentLength: parseInt(response.headers.get("Content-Length")),
            contentType: response.headers.get("Content-Type"),
          }
          setTotalNumberOfBytes(responseHeaders.contentLength);
          const body = new Uint8Array(responseHeaders.contentLength);
          const reader = response.body.getReader();
          let offset = 0;
          while (!controller.signal.aborted) {
            const { value, done } = await reader.read();
            if (done) {
                const blob = new Blob([body.buffer], { type: responseHeaders.contentType });
                setOriginalImageUrl(URL.createObjectURL(blob));
                setOriginalImageFile(blob);
                break;
            }
            body.set(value, offset);
            offset += value.length
            setNumberOfBytesReceived(offset);
          }
        } catch(e) {
          addMessage({
            type: "error",
            title: "Något gick fel när originalbilden skulle hämtas.",
            description: e.message,
          })
        } finally {
          setIsLoading(false)
        }
      })();
      return () => {
        URL.revokeObjectURL(originalImageUrl)
        controller.abort()
      };
    }, [employeeNo, accessToken, addMessage]);

    useEffect(() => {
      if (totalNumberOfBytes === 0) return;
      const percent = Math.round((numberOfBytesReceived / totalNumberOfBytes) * 100)
      setPercentReceived(percent)
    }, [totalNumberOfBytes, numberOfBytesReceived])


    return [originalImageUrl, originalImageFile, isLoading, percentReceived]
}

export default useOriginalImage