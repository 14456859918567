import React from "react";
import PageLayoutStatic from "../components/PageLayoutStatic";
import ManageEmployeesPage from "./ManageEmployeesPage";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { Link } from "react-router-dom";
import { globalClasses } from "../styles/GlobalClasses";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { hasRequiredRoles, ADMIN } from "../auth";

export default function HomePage() {
  const { identity } = useContext(AuthContext);

  const hasUserAccess = hasRequiredRoles(identity, [ADMIN]);

  return (
    <PageLayoutStatic>
      <AuthenticatedTemplate>
        {identity && !hasUserAccess && (
          <MessageBar messageBarType={MessageBarType.warning}>
            Du saknar rättigheter att redigera bilder. Kontakta IT för
            behörighet
          </MessageBar>
        )}
        {hasUserAccess && <ManageEmployeesPage />}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <MessageBar messageBarType={MessageBarType.warning}>
          Du är inte inloggad.
          <Link to="/login" className={globalClasses.link}>
            Logga in här
          </Link>
        </MessageBar>
      </UnauthenticatedTemplate>
    </PageLayoutStatic>
  );
}
