import {
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import React, { useRef } from "react";
import { stackTokens } from "../styles/stackConstants";

const classes = mergeStyleSets({
  input: {
    display: "none",
  },
  card: {
    alignItems: "center",
    textAlign: "center",
  },
});

export default function ImagePicker({ onChange, errorMessage }) {
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <Stack className={classes.card} tokens={stackTokens}>
      <Stack tokens={stackTokens}>
        <Text variant="xLarge">Ladda upp en bild</Text>
        <Text>Bilden måste vara i JPG-format</Text>
      </Stack>
      <Stack tokens={stackTokens}>
        <input
          ref={inputRef}
          className={classes.input}
          type="file"
          accept="image/jpeg"
          onChange={onChange}
        />
        <PrimaryButton
          text="Välj ny bild"
          iconProps={{ iconName: "Upload" }}
          onClick={handleClick}
        />
        {errorMessage && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {errorMessage}
          </MessageBar>
        )}
      </Stack>
    </Stack>
  );
}
