import { useMsal } from '@azure/msal-react'
import { useMemo } from 'react'
const useIdentity = () => {
  const { accounts } = useMsal()

  const identity = useMemo(() => (Array.isArray(accounts) && accounts.length ? accounts[0] : undefined), [accounts])

  return identity
}
export default useIdentity
