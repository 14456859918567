import React from 'react'
import { useMsal } from '@azure/msal-react'
import { CommandBarButton } from '@fluentui/react'
import { signOutAsync } from '../../api/msal'

export const SignOutButton = ({ style }) => {
  const { instance } = useMsal()

  return <CommandBarButton text="Logga ut" style={style || {}} onClick={() => signOutAsync(instance)} />
}
