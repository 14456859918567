import { ChoiceGroup, PrimaryButton, Spinner, Stack } from "@fluentui/react";
import React from "react";
import { stackTokens, stackTokensLarge } from "../styles/stackConstants";

export default function SelectionForm({
  label,
  options,
  defaultSelectedKey,
  onChange,
  onSave,
  isSaving,
  btnText,
}) {
  return (
    <Stack tokens={stackTokensLarge}>
      <ChoiceGroup
        defaultSelectedKey={defaultSelectedKey}
        options={options}
        onChange={onChange}
        label={label}
        required={true}
      />
      <Stack horizontal tokens={stackTokens}>
        <PrimaryButton text={btnText} onClick={onSave} disabled={isSaving} />
        {isSaving && <Spinner />}
      </Stack>
    </Stack>
  );
}
