import { mergeStyleSets, Stack } from "@fluentui/react";
import React from "react";

const pageLayoutStyling = mergeStyleSets({
  root: {
    padding: "1.5rem 4rem 3rem 4rem",
    height: "100%",

    "@media(max-width: 1023px)": {
      padding: "1.5rem 1.5rem 3rem 1.5rem",
    },
  },
});

export default function PageLayoutStatic({ children }) {
  return <Stack styles={pageLayoutStyling}>{children}</Stack>;
}
