import {
  DocumentCard,
  DocumentCardDetails,
  DocumentCardImage,
  DocumentCardTitle,
  ImageFit,
  mergeStyleSets,
  ProgressIndicator,
  Spinner,
} from "@fluentui/react";
import React from "react";
import { theme } from "../styles/Theme";

const cardStyles = mergeStyleSets({
  root: {
    width: 420,
    maxWidth: 420
  },
});

const imageStyles = mergeStyleSets({
  root: { padding: "20px", background: theme.palette.white },
});

const placeholderIconProps = {
  iconName: "Contact",
  styles: {
    root: {
      fontSize: "120px",
      width: "120px",
      height: "120px",
    },
  },
};

export default function ProfileImageCard({
  title,
  isLoading,
  percentComplete,
  imageUrl,
  children,
  hasOnGoingJob,
  targetSystems,
}) {
  const getClass = (isLoading, hasOnGoingJob) => {
    return mergeStyleSets({
      cardImageContainer: {
        height: isLoading || hasOnGoingJob ? "210px" : "",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        borderBottom: `1px solid ${theme.palette.borderColor}`,
      },
    });
  };
  const { cardImageContainer } = getClass(isLoading, hasOnGoingJob);

  const titleStyles = mergeStyleSets({
    root: {
      textAlign: "center",
      height: targetSystems?.length > 0 ? "none" : "38px",
    },
  });

  return (
    <DocumentCard aria-label={"Card with image."} styles={cardStyles}>
      {isLoading ? (
        <div className={cardImageContainer}>
          {percentComplete
            ? <ProgressIndicator label={`Bilden laddas ${percentComplete}%`} percentComplete={percentComplete / 100} />
            : <Spinner />
          }
        </div>
      ) : children !== null ? (
        <div className={cardImageContainer}>{children}</div>
      ) : hasOnGoingJob ? (
        <div className={cardImageContainer}>
          <ProgressIndicator label="Bilden processas" />
        </div>
      ) : (
        <DocumentCardImage
          height={210}
          imageFit={ImageFit.contain}
          imageSrc={imageUrl ? imageUrl : ""}
          iconProps={!imageUrl ? placeholderIconProps : ""}
          styles={imageStyles}
        />
      )}
      <DocumentCardDetails>
        <div style={{ height: targetSystems?.length > 0 ? "150px" : "none" }}>
          <DocumentCardTitle title={title} styles={titleStyles} />
          {targetSystems?.length > 0 &&
            targetSystems.map((target) => (
              <DocumentCardTitle
                key={target.id}
                title={target.name}
                showAsSecondaryTitle
                styles={titleStyles}
              />
            ))}
        </div>
      </DocumentCardDetails>
    </DocumentCard>
  );
}
