import {
  approvalEndpoint,
  approvalUpdateEndpoint,
  cropDataEndpoint,
  cropFormatsEndpoint,
  employeeByEmployeeNoEndpoint,
  employeeFromGraphEndpoint,
  focalPointEndpoint,
  pendingJobCountEndpoint,
  targetSystemsEndpoint,
} from "../helpers/apiUrls";
import { getAccessTokenAsync } from "./msal";

export const fetchEmployee = async (
  signal,
  accessToken,
  employeeNo
) => {

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  if (signal) options.signal = signal;

  const url = employeeByEmployeeNoEndpoint(employeeNo);
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      const employee = {
        id: result.id,
        employeeNo: result.employeeNo,
        name: result.name,
        approvalStatusId: result.approvalStatusId,
      };
      return employee;
    })
};

export const fetchTargetSystems = async (
  signal,
  accessToken,
  employeeNo,
) => {

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  if (signal) options.signal = signal;

  const url = targetSystemsEndpoint;
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      const targetSystems = result.map((targetSystem) => ({
        id: targetSystem.id,
        name: targetSystem.name,
        getEndpoint: targetSystem.getEndpoint,
        uploadEndpoint: targetSystem.uploadEndpoint,
        cropFormatId: targetSystem.cropFormatId,
        outputWidth: targetSystem.outputWidth,
        outputHeight: targetSystem.outputHeight,
        grayScale: targetSystem.grayScale,
      }));
      return targetSystems;
    })
};

export const fetchCropFormats = async (signal, accessToken) => {

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  if (signal) options.signal = signal;

  const url = cropFormatsEndpoint;
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      const cropFormats = result.map((cropFormat) => ({
        id: cropFormat.id,
        name: cropFormat.name,
        aspectRatioWidth: cropFormat.aspectRatioWidth,
        aspectRatioHeight: cropFormat.aspectRatioHeight,
      }));
      return cropFormats;
    })
};

export const fetchImage = async (
  signal,
  accessToken,
  employeeNo,
  endpointUrl
) => {

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  if (signal) options.signal = signal;

  const url = `${process.env.REACT_APP_SERVER_URI}${endpointUrl}`.replace("{employeeNo}", employeeNo);
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 404) {
          return null;
        } else {
          throw new Error(response.statusText);
        }
      }
      return response.blob();
    })
    .then((blob) => {
      return blob;
    })
};

export const uploadImage = async (
  signal,
  instance,
  identity,
  formData,
  endpointUrl,
  systemName,
  onError,
  onProgress
) => {
  const accessToken = await getAccessTokenAsync(instance, identity, [
    process.env.REACT_APP_SCOPES,
  ]);

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const xhr = new XMLHttpRequest();

  function updateProgress(e) {
    onProgress(e.loaded / e.total)
  }

  function abortRequest() {
    xhr.upload?.removeEventListener("progress", updateProgress)
    xhr.abort()
  }

  signal?.addEventListener("abort", abortRequest)

  const url = `${process.env.REACT_APP_SERVER_URI}${endpointUrl}`;

  return new Promise((resolve, reject) => {
    xhr.upload.addEventListener('progress', updateProgress);
    xhr.addEventListener('load', () => resolve(xhr.response));
    xhr.addEventListener('error', () => reject(new Error('File upload failed')));
    xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
    xhr.open('POST', url, true);
    xhr.setRequestHeader("Authorization", bearer)
    xhr.send(formData);
  }).then(response => {
    return response;
  }).catch((error) => {
    onError(error);
  }).finally(() => {
    signal?.removeEventListener("abort", abortRequest)
  });
};

export const fetchFocalPoint = async (
  signal,
  accessToken,
  employeeNo
) => {

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  if (signal) options.signal = signal;

  const url = focalPointEndpoint(employeeNo);
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 404) {
          return null;
        } else {
          throw new Error(response.statusText);
        }
      }
      return response.json();
    })
    .then((result) => {
      if (!result) return null
      const focalPoint = [result.x, result.y];
      return focalPoint;
    })
};

export const uploadFocalPoint = async (
  signal,
  accessToken,
  employeeNo,
  focalPoint,
  onError
) => {

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    body: JSON.stringify({
      x: focalPoint[0],
      y: focalPoint[1],
    }),
    headers,
  };

  if (signal) options.signal = signal;

  const url = focalPointEndpoint(employeeNo);
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${response.statusText}`);
      }
    })
    .catch((error) => {
      onError(error)
      return null;
    });
};

export const fetchCropData = async (signal, accessToken, employeeNo) => {

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  if (signal) options.signal = signal;

  const url = cropDataEndpoint(employeeNo);
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        if (response.status === 404) {
          return null;
        } else {
          throw new Error("Failed to fetch Crop data");
        }
      }
      return response.json();
    })
    .then((result) => {
      return result;
    })
};

export const uploadCropData = async (
  signal,
  accessToken,
  employeeNo,
  cropData,
  onError
) => {

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers,
    body: JSON.stringify(cropData),
  };

  if (signal) options.signal = signal;

  const url = cropDataEndpoint(employeeNo);

  //TODO: Hur hantera och ta emot fel
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${response.statusText}`);
      }
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      onError(error);
      return null;
    });
};

export const fetchCroppingStatus = async (
  signal,
  accessToken,
  employeeNo,
  cropFormatId
) => {

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  if (signal) options.signal = signal;

  const url = pendingJobCountEndpoint(employeeNo, cropFormatId);
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      if (result > 0) {
        return true;
      }
      return false;
    })
};

export const fetchEmployeeFromGraph = async (
  signal,
  instance,
  identity,
  userId
) => {
  const accessToken = await getAccessTokenAsync(instance, identity, [
    process.env.REACT_APP_SCOPES,
  ]);

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers,
  };

  if (signal) options.signal = signal;

  const url = employeeFromGraphEndpoint(userId);
  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch employee");
      }
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return null;
    });
};

export const uploadApproval = async (
  signal,
  instance,
  identity,
  employeeNo,
  approvalStatusId,
  onError
) => {
  const accessToken = await getAccessTokenAsync(instance, identity, [
    process.env.REACT_APP_SCOPES,
  ]);

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "POST",
    headers,
  };

  if (signal) options.signal = signal;

  const url = approvalEndpoint(employeeNo, approvalStatusId);

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${response.statusText}`);
      }
      return response.json;
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      onError(error);
      return null;
    });
};

export const sendNewApproval = async (
  signal,
  instance,
  identity,
  employeeNo
) => {
  const accessToken = await getAccessTokenAsync(instance, identity, [
    process.env.REACT_APP_SCOPES,
  ]);

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "POST",
    headers,
  };

  if (signal) options.signal = signal;

  const url = approvalUpdateEndpoint(employeeNo);

  return fetch(url, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${response.statusText}`);
      }
      return response.json;
    })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
};
