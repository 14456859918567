import {
  CommandBarButton,
  getInitials,
  Persona,
  PersonaSize,
} from "@fluentui/react";
import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { theme } from "../../styles/Theme";
import UserProfileCard from "../UserProfileCard";

const ProfileButton = () => {
  const [showProfile, setShowProfile] = useState(false);
  const user = useContext(AuthContext);
  const initials = useMemo(() => getInitials(user?.name), [user]);

  const styles = {
    root: {
      padding: "8px",
      backgroundColor: "transparent",
    },
    rootHovered: {
      backgroundColor: "hsla(0,0%,100%,.08)",
    },
    rootPressed: {
      backgroundColor: "hsla(0,0%,100%,.08)",
    },
  };

  const classes = {
    persona: { width: 32 },
  };

  return (
    <>
      <CommandBarButton toggle onClick={setShowProfile} styles={styles}>
        <Persona
          initialsColor={"hsla(0,0%,100%,.08)"}
          initialsTextColor={theme.palette.headerTextColor}
          size={PersonaSize.size32}
          imageUrl={user?.profilePhoto}
          className={classes.persona}
          imageInitials={initials}
        />
      </CommandBarButton>
      {showProfile && (
        <UserProfileCard
          userName={user.identity?.name}
          userEmail={user.identity?.username}
          userProfilePhoto={user?.profilePhoto}
          close={setShowProfile}
        />
      )}
    </>
  );
};

export default ProfileButton;
