import React from "react";
import { useMsal } from "@azure/msal-react";
import { CommandBarButton, mergeStyleSets } from "@fluentui/react";
import { signInAsync } from "../../api/msal";
import { theme } from "../../styles/Theme";

export const SignInButton = ({ backgroundColor, backgroundColorHover }) => {
  const classes = mergeStyleSets({
    root: {
      padding: "17px 8px",
      backgroundColor: `${backgroundColor || "transparent"}`,
      position: "relative",
      color: theme.palette.white,
    },

    rootHovered: {
      backgroundColor: `${backgroundColorHover || "hsla(0,0%,100%,.08)"}`,
      color: theme.palette.white,
    },

    rootPressed: {
      backgroundColor: `${backgroundColorHover || "hsla(0,0%,100%,.08)"}`,
      color: theme.palette.white,
    },
  });

  const { instance } = useMsal();
  return (
    <CommandBarButton
      styles={classes}
      text="Logga in"
      onClick={() => signInAsync(instance)}
    />
  );
};
