import React from "react";
import {
  IconButton,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";

import { stackTokens } from "../../styles/stackConstants";
import { theme } from "../../styles/Theme";

const classes = mergeStyleSets({
  StyledModalContainer: {
    borderTop: `4px solid ${theme.palette.themePrimary}`,
    color: `${theme.palette.neutralPrimary}`,
    display: "flex",
    flexDirection: "column",
  },

  StyledModalHeader: {
    display: "flex",
    alignitems: "center",
    justifyContent: "space-between",
    padding: "12px 12px 14px 24px",
  },

  StyledModalBody: { padding: "0 24px" },

  StyledModalFooter: {
    padding: "16px 24px 24px",
  },
});

export default function ErrorMessage({
  message,
  isOpen,
  onRequestClose,
  onMount,
  onConfirm,
}) {

  return (
    <Modal
      isOpen={isOpen}
      isBlocking={false}
      onDismiss={onRequestClose}
      layerProps={{ onLayerDidMount: onMount }}
    >
      <div className={classes.StyledModalContainer}>
        <div className={classes.StyledModalHeader}>
          <Text variant="large">{message.title}</Text>
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            onClick={onRequestClose}
          />
        </div>

        <div className={classes.StyledModalBody}>
          <Text variant={"medium"}>{message.description}</Text>
        </div>
        <div className={classes.StyledModalFooter}>
          <Stack horizontal tokens={stackTokens}>
            <PrimaryButton text={"OK"} onClick={onConfirm || onRequestClose} />
          </Stack>
        </div>
      </div>
    </Modal>
  );
}
