import React, { useContext } from "react";
import { EmployeesContext } from "../context/EmployeesContext";
import { theme } from "../styles/Theme";
import EmployeesTable from "../components/EmployeesTable";
import SearchEmployees from "../components/SearchEmployees";
import {
  mergeStyleSets,
  MessageBar,
  Spinner,
  Stack,
  StackItem,
} from "@fluentui/react";
import { stackTokens } from "../styles/stackConstants";
import PageLayoutStatic from "../components/PageLayoutStatic";

const containerStyling = mergeStyleSets({
  root: {
    flex: "1",
    minWidth: "300px",
    maxWidth: "600px",
  },
});

const stackItemStyling = mergeStyleSets({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "2rem",
  },
});

export default function ManageEmployeesPage() {
  const { employees, setEmployees, isLoading } = useContext(EmployeesContext);

  return (
    <PageLayoutStatic>
      <Stack tokens={stackTokens} styles={containerStyling}>
        <Stack>
          <StackItem>
            <h1 style={theme.fonts.xLarge}>Sök</h1>
          </StackItem>
          <SearchEmployees />
        </Stack>
        <Stack styles={containerStyling}>
          <StackItem>
            <h1 style={theme.fonts.xLarge}>Väntar på godkännande från:</h1>
          </StackItem>
          {!isLoading && employees.length > 0 ? (
            <EmployeesTable items={employees} setItems={setEmployees} />
          ) : isLoading ? (
            <StackItem styles={stackItemStyling}>
              <Spinner label="Listan laddas in" />
            </StackItem>
          ) : (
            <MessageBar>Vi inväntar inga godkännanden</MessageBar>
          )}
        </Stack>
      </Stack>
    </PageLayoutStatic>
  );
}
