import { useContext } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { AuthContext } from "../context/AuthContext";
import { hasRequiredRoles } from "../auth";
  
export const ProtectedContent = ({ isProtected, roles, children }) => {
    const { identity } = useContext(AuthContext);
    const isAuthorized = hasRequiredRoles(identity, roles);
    return (
        <>
        <AuthenticatedTemplate>
            {(isAuthorized && (!roles || isAuthorized)) && children}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            {!isProtected && children}
        </UnauthenticatedTemplate>
        </>
    );
};


