import {
  mergeStyleSets,
  Overlay,
  PrimaryButton,
  ProgressIndicator,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageLayout from "../components/PageLayout";
import { useMsal } from "@azure/msal-react";
import { AuthContext } from "../context/AuthContext";
import { MessagesContext } from "../context/MessagesContext";
import DisplayMessages from "../components/Messages/DisplayMessages";
import {
  uploadCropData,
  uploadFocalPoint,
  uploadImage,
} from "../api/profileImagesHandler";
import ImagePicker from "../components/ImagePicker";
import ImageWithFocalPoint from "../components/ImageWithFocalPoint";
import ProfileImageCard from "../components/ProfileImageCard";
import CropperCard from "../components/CropperCard";
import useEmployee from "../hooks/useEmployee";
import useCropFormats from "../hooks/useCropFormats";
import useFocalPoint from "../hooks/useFocalPoint";
import useCropData from "../hooks/useCropData";
import useOriginalImage from "../hooks/useOriginalImage";
import { useMemo } from "react";
import { stackTokens } from "../styles/stackConstants";
import useTargetSystems from "../hooks/useTargetSystems";
import useMsGraphToken from "../hooks/useMsGraphToken";
import { EmployeesContext } from "../context/EmployeesContext";

const classes = mergeStyleSets({
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "25px",
  },
});

export default function EditUserImagesPage() {
  const { employeeNo } = useParams();
  const { instance } = useMsal();
  const { identity } = useContext(AuthContext);
  const { setEmployees } = useContext(EmployeesContext);
  const scopes = useMemo(() => [process.env.REACT_APP_SCOPES], [])
  const accessToken = useMsGraphToken(scopes)
  const { addMessage } = useContext(MessagesContext);
  const focalPointDefaultValue = useMemo(() => [0.5, 0.5], []);
  const [isLoading, setIsLoading] = useState(true);
  const [employee, employeeIsLoading] = useEmployee(employeeNo, addMessage);
  const [targetSystems, targetSystemsIsLoading] = useTargetSystems(
    employeeNo,
    addMessage
  );
  const [cropFormats, cropFormatsAreLoading] = useCropFormats(addMessage);
  const [initialFocalPoint, initialFocalPointIsLoading] = useFocalPoint(
    employeeNo,
    focalPointDefaultValue
  );
  const [focalPoint, setFocalPoint] = useState(null);
  const [initialCropData, initialCropDataIsLoading] = useCropData(employeeNo);
  const [cropData, setCropData] = useState([]);
  const [
    initialOriginalImageUrl,
    initialOriginalImageFile,
    initialOriginalImageIsLoading,
    initialOriginalImagePercentReceived,
  ] = useOriginalImage(employeeNo);
  const [originalImageUrl, setOriginalImageUrl] = useState(null);
  const [originalImageFile, setOriginalImageFile] = useState(null);
  const [errorMessage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);

  const resetFocalPoint = useCallback(() => {
    setFocalPoint(focalPointDefaultValue);
  }, [focalPointDefaultValue]);

  const resetCropData = useCallback(() => {
    cropFormats.reduce((acc, current) => {
      acc[current.id] = null;
      return acc;
    }, {});
  }, [cropFormats]);

  let navigate = useNavigate();

  useEffect(() => {
    if (!initialFocalPointIsLoading && initialFocalPoint)
      setFocalPoint(initialFocalPoint);
  }, [initialFocalPoint, initialFocalPointIsLoading]);

  useEffect(() => {
    if (!initialCropDataIsLoading && initialCropData)
      setCropData(initialCropData);
  }, [initialCropData, initialCropDataIsLoading]);

  useEffect(() => {
    if (
      !initialOriginalImageIsLoading &&
      initialOriginalImageFile &&
      initialOriginalImageUrl
    ) {
      setOriginalImageFile(initialOriginalImageFile);
      setOriginalImageUrl(initialOriginalImageUrl);
    }
  }, [
    initialOriginalImageUrl,
    initialOriginalImageFile,
    initialOriginalImageIsLoading,
  ]);

  useEffect(() => {
    const isAnyLoading = [
      employeeIsLoading,
      cropFormatsAreLoading,
      initialFocalPointIsLoading,
      initialCropDataIsLoading,
      initialOriginalImageIsLoading,
      targetSystemsIsLoading,
    ].find((value) => value);
    if (!isAnyLoading) setIsLoading(false);
  }, [
    employeeIsLoading,
    cropFormatsAreLoading,
    initialFocalPointIsLoading,
    initialCropDataIsLoading,
    initialOriginalImageIsLoading,
    targetSystemsIsLoading,
  ]);

  const onImagePickerChange = (e) => {
    const file = e.target.files[0];
    setOriginalImageFile(file);
    setOriginalImageUrl(URL.createObjectURL(file));
    resetCropData();
    resetFocalPoint();
  };

  const onSaveSingleFormat = async (cropFormatId) => {
    const cropDataItem = cropData[cropFormatId];

    await uploadCropData(
      null,
      accessToken,
      employeeNo,
      [cropDataItem],
      (error) => {
        addMessage({
          type: "error",
          title: `Något gick fel när bilden skulle laddas upp för Original.`,
          description: `${error}`,
        });
      }
    ).then(() => {
      navigate(`/user/${employeeNo}`, { replace: false });
    });
  };

  const onSave = async () => {
    setIsSaving(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append("file", originalImageFile);

    const endpointUrl = `/ProfileImage/OriginalProfileImage/${employeeNo}`;
    let employeeDetails = null;
    let errors = [];

    try {

      await uploadImage(
        null,
        instance,
        identity,
        formData,
        endpointUrl,
        "Original",
        (errorMessage) => {
          errors.push({ 
            title: "Det gick inte att ladda upp den nya originalbilden",
            description: errorMessage
          })
          throw new Error()
        },
        (fraction) => {
          setUploadProgress(fraction)
        }
      )
      employeeDetails = await uploadCropData(
        null,
        accessToken,
        employeeNo,
        Object.entries(cropData).map(([_, value]) => value),
        (errorMessage) => {
          errors.push({ 
            title: "Det gick inte att ladda upp beskärningsinformationen",
            description: errorMessage
          })
          throw new Error()
        }
      )

      await uploadFocalPoint(
        null,
        accessToken,
        employeeNo,
        focalPoint,
        (errorMessage) => {
          errors.push({ 
            title: "Det gick inte att ladda upp fokuspunkten",
            description: errorMessage
          })
          throw new Error()
        }
      )

    } catch(error) {
      const errorMessages = errors?.map(err => err.title).join("<br>")
      addMessage({
        type: "error",
        title: "Ett eller flera fel uppstod. Försök igen.",
        description: "Tyvärr så gick det inte att behandla bilden. Prova att ladda upp bilden igen. Om det fortfarande inte fungerar. Kontakta support.\n\n" + errorMessages,
      });
    } finally {

      setIsSaving(false);

      // Add the employee to the list with pending requests
      if (employeeDetails && employeeDetails.approvalStatusId === 1) {
        setEmployees(employees => {
          if (!employees.find(employee => employee.employeeNo === employeeDetails.employeeNo)) {
            const next = [...employees];            
            next.push({
              employeeNo: employeeDetails.employeeNo,
              name: employeeDetails.name,
              created: new Date(employeeDetails.createdDate).toLocaleDateString("sv-SE"),
              sent: new Date(employeeDetails.sendDate).toLocaleDateString("sv-SE")
            })
            return next;
          }
          return employees;
        })
      }
      navigate(`/user/${employeeNo}`, { replace: false });
    }
  };

  const onCropChange = (cropFormatId, cropFormatCropData) => {
    setCropData((current) => {
      const next = { ...current };
      next[cropFormatId] = cropFormatCropData;
      return next;
    });
  };

  const reuseOriginalImageClick = () => {    
    setOriginalImageUrl(URL.createObjectURL(originalImageFile));
    resetCropData();
    resetFocalPoint();
  };
  
  return (
    <PageLayout>
 
      {employee && cropFormats?.length > 0 && (
        <Stack tokens={{ childrenGap: 40 }}>

      {/* <div style={{position:"absolute", width:"inherit", height:"90px", background:"rgba(0,0,0,.5)"}}>test</div> */}

          <Stack tokens={{ childrenGap: 40 }}>
            <Text variant="xLarge">{employee.name}</Text>
            <StackItem grow>
              <Stack horizontal tokens={{ childrenGap: 25 }}>
                <Stack tokens={stackTokens}>
                  <ProfileImageCard
                    title="Original"
                    isLoading={initialOriginalImageIsLoading}
                    imageUrl={originalImageUrl}
                    percentComplete={initialOriginalImagePercentReceived}
                  >
                    {originalImageUrl && focalPoint && (
                      <ImageWithFocalPoint
                        focalPoint={focalPoint}
                        setFocalPoint={setFocalPoint}
                        imgSrc={originalImageUrl}
                      />
                    )}
                  </ProfileImageCard>
                  {originalImageUrl && (
                    <a download={`${employeeNo}.jpg`} href={originalImageUrl}>
                      <PrimaryButton
                        text="Ladda ner originalbild"
                        iconProps={{ iconName: "Download" }}
                        style={{ width: "100%" }}
                      />
                    </a>
                  )}
                  {originalImageUrl && (                    
                      <PrimaryButton
                        text="Återanvänd originalbild"
                        iconProps={{ iconName: "Upload" }}
                        style={{ width: "100%" }}
                        onClick={reuseOriginalImageClick}
                      />                    
                  )}
                </Stack>
                <StackItem align="center">
                  <ImagePicker
                    onChange={onImagePickerChange}
                    errorMessage={errorMessage}
                  />
                </StackItem>
                {originalImageUrl !== initialOriginalImageUrl && (
                  <StackItem>
                    {!isSaving && <PrimaryButton text="Spara" onClick={onSave} />}
                  </StackItem>
                )}
              </Stack>
            </StackItem>
          </Stack>
          {originalImageUrl && (
            <Stack tokens={{ childrenGap: 40 }}>
              <Text variant="xLarge">Format att beskära:</Text>
              {isLoading && <Spinner />}
              {!isLoading && originalImageUrl && focalPoint && (
                <Stack tokens={{ childrenGap: 40 }}>
                  <div className={classes.imageContainer}>
                    {cropFormats
                      .filter((cf) => true)
                      .map((cropFormat, i) => {
                        return (
                          <CropperCard
                            key={cropFormat.id}
                            aspectRatioWidth={cropFormat.aspectRatioWidth}
                            aspectRatioHeight={cropFormat.aspectRatioHeight}
                            isLoading={isLoading}
                            originalImageUrl={originalImageUrl}
                            focalPoint={focalPoint}
                            onSave={onSaveSingleFormat}
                            cropFormatId={cropFormat.id}
                            initialCropData={
                              initialCropData && initialCropData[cropFormat.id]
                            }
                            hasOriginalImageChanged={
                              originalImageUrl !== initialOriginalImageUrl
                            }
                            onCropChange={onCropChange}
                            targetSystems={targetSystems}
                          />
                        );
                      })}
                  </div>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      )}
      {isSaving &&
        <Overlay isDarkThemed={false}>
          <div style={{
            width:'100%',
            height:'100%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            {
              uploadProgress && uploadProgress !== 1
              ? <ProgressIndicator label={`Laddar upp bilden ${Math.round(uploadProgress * 100)}%`} percentComplete={uploadProgress} />
              : <Spinner size={SpinnerSize.large} label="Behandlar bilden..." />
            }
          </div>
        </Overlay>
      }
      <DisplayMessages />
    </PageLayout>
  );
}
