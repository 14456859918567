import React, { useContext, useEffect, useState } from "react";
import {
  fetchCroppingStatus,
  fetchImage,
} from "../api/profileImagesHandler";
import ProfileImageCard from "./ProfileImageCard";
import { useMemo } from "react";
import useMsGraphToken from "../hooks/useMsGraphToken";
import { MessagesContext } from "../context/MessagesContext";


export default function TargetSystemCard({ targetSystem, employeeNo, onMaxRetries }) {
  const scopes = useMemo(() => [process.env.REACT_APP_SCOPES], [])
  const accessToken = useMsGraphToken(scopes)
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasOnGoingJob, setHasOnGoingJob] = useState(true);
  const {addMessage} = useContext(MessagesContext)


  useEffect(() => {
    let abortController = null;
    if(!hasOnGoingJob) {
      abortController = new AbortController();
      (async (systemName, endpointUrl, abortController) => {
        setIsLoading(true);
        await fetchImage(
          abortController.signal,
          accessToken,
          employeeNo,
          endpointUrl,
        )
          .then((blob) => {
            if (blob) {
              setImage({ type: `${systemName}`, url: URL.createObjectURL(blob) });
            }
          })
          .catch((e) => {
            if (e.message.indexOf("The user aborted a request.") === -1) return;
            addMessage({
              type: "error",
              title: `Något gick fel när bilden för ${systemName} skulle hämtas.`,
              description: e.message,
            })
          }).finally(() => {
            setIsLoading(false)
          })
      })(targetSystem.name, targetSystem.getEndpoint, abortController);
    }
    return () => {abortController?.abort()}
  }, [hasOnGoingJob])

  useEffect(() => {
    if(!accessToken) return;

    const getCroppingStatus = async (targetSystemCropFormatId, abortController) => {
      await fetchCroppingStatus(
        abortController.signal,
        accessToken,
        employeeNo,
        targetSystemCropFormatId
      )
        .then((result) => {
          setHasOnGoingJob(result);
          if(result === false) {
            clearInterval(intervalId)
          }
        })
        .catch((e) => {
          if (e.message.indexOf("The user aborted a request.") === -1) return;
          addMessage({
            type: "error",
            title: `Failed to fetch cropping status for cropformat with id ${targetSystemCropFormatId}`,
            description: e.message,
          })
        })
    };

    let abortController = null;
    let intervalId;
    let retries = 0
    const maxRetries = 6

    const intervalFn = () => {
      (async () => {
        abortController?.abort()

        if (retries > maxRetries || !hasOnGoingJob) {
          onMaxRetries()
          clearInterval(intervalId)
          return
        }

        retries++

        abortController = new AbortController();
        await getCroppingStatus(targetSystem.cropFormatId, abortController)
      })()
    }
    
    if (targetSystem && employeeNo && hasOnGoingJob) {

      intervalId = setInterval(intervalFn, 10000)
      intervalFn()
    
    }

    return () => {abortController?.abort(); clearInterval(intervalId)}
  }, [targetSystem, employeeNo, accessToken, addMessage]);

  return (
    <ProfileImageCard
      title={targetSystem.name}
      isLoading={isLoading}
      imageUrl={image?.url}
      children={null}
      hasOnGoingJob={hasOnGoingJob}
    />
  );
}
