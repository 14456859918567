import { PrimaryButton, Stack, StackItem } from "@fluentui/react";
import React from "react";
import ImageCropper from "./ImageCropper";
import ProfileImageCard from "./ProfileImageCard";

export default function CropperCard({
  aspectRatioWidth,
  aspectRatioHeight,
  originalImageUrl,
  focalPoint,
  onSave,
  cropFormatId,
  initialCropData,
  hasOriginalImageChanged,
  onCropChange,
  targetSystems,
}) {
  return (
    <Stack tokens={{ childrenGap: 25 }}>
      <ProfileImageCard
        title={`Format ${aspectRatioWidth}/${aspectRatioHeight}`}
        imageUrl={originalImageUrl}
        targetSystems={targetSystems.filter(
          (target) => target.cropFormatId === cropFormatId
        )}
      >
        <ImageCropper
          cropFormatId={cropFormatId}
          focalPoint={focalPoint}
          imgSrc={originalImageUrl}
          aspectRatio={aspectRatioWidth / aspectRatioHeight}
          initialCropData={initialCropData}
          hasOriginalImageChanged={hasOriginalImageChanged}
          onCropChange={onCropChange}
        />
      </ProfileImageCard>
      {!hasOriginalImageChanged && (
        <StackItem>
          <PrimaryButton text="Spara" onClick={() => onSave(cropFormatId)} />
        </StackItem>
      )}
    </Stack>
  );
}
