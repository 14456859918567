import React, { useEffect, useRef } from "react";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

export default function ImageCropper({
  cropFormatId,
  imgSrc,
  aspectRatio,
  focalPoint,
  initialCropData,
  hasOriginalImageChanged,
  onCropChange,
}) {
  const cropperRef = useRef(null);

  useEffect(() => {
    function onReady() {
      const currentCropData = cropperRef.current.cropper.getData();
      currentCropData.x = initialCropData.x;
      currentCropData.y = initialCropData.y;
      currentCropData.width = initialCropData.width;
      currentCropData.height = initialCropData.height;
      cropperRef.current.cropper.setData(currentCropData);
    }
    const ref = cropperRef.current
    ref.addEventListener("ready", onReady)
    return () => ref?.removeEventListener("ready", onReady)
  }, [initialCropData])

  useEffect(() => {
    const currentCropData = cropperRef.current.cropper.getData();

    const imageData = cropperRef.current?.cropper.getImageData();
    // Calculating placement of cropper to have focalpoint in the center.
    // Picture naturalwidth/height x focalpoint(decimal) = center of image
    // minus half of the cropperBox width/height
    // Then we will get the placement of the cropperbox left top corner from the pictures left corner.
    currentCropData.x =
      imageData.naturalWidth * focalPoint[0] - currentCropData.width / 2;
    currentCropData.y =
      imageData.naturalHeight * focalPoint[1] - currentCropData.height / 2;
    
    cropperRef.current.cropper.setData(currentCropData);

  }, [focalPoint, hasOriginalImageChanged])

  return (
    <Cropper
      id={cropFormatId}
      ref={cropperRef}
      aspectRatio={aspectRatio}
      src={imgSrc}
      viewMode={1}
      background={false}
      responsive={false}
      autoCropArea={1}
      autoCrop={true}
      zoomable={false}
      crop={(e) => {
        const next = {
          ...initialCropData,
          x: Math.round(e.detail.x),
          y: Math.round(e.detail.y),
          width: Math.round(e.detail.width),
          height: Math.round(e.detail.height),
          cropFormatId: cropFormatId,
        };
        onCropChange(cropFormatId, next);
      }}
    />
  );
}
