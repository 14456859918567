import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ContextualMenu, SearchBox, Stack } from "@fluentui/react";
import { getAccessTokenAsync } from "../api/msal";
import { useMsal } from "@azure/msal-react";
import { debounce } from "../helpers/debounce";
import { useNavigate } from "react-router-dom";
import { searchEndpoint } from "../helpers/apiUrls";
import { AuthContext } from "../context/AuthContext";

export default function SearchEmployees() {
  const [userSearchString, setUserSearchString] = useState();
  const [userSearchResult, setUserSearchResult] = useState([]);
  const [showContextualMenu, setShowContextualMenu] = useState(false);

  let navigate = useNavigate();
  const { instance } = useMsal();
  const { identity } = useContext(AuthContext);
  const linkRef = useRef(null);

  const onHideContextualMenu = useCallback(
    () => setShowContextualMenu(false),
    []
  );

  const onItemClick = (user) => {
    navigate(`/user/${user.employeeNo}`, { replace: false, state: user });
    setShowContextualMenu(false);
  };

  const debounceSetSearchString = debounce(
    (value) => setUserSearchString(value),
    350
  );

  useEffect(() => {
    const searchEmployees = async (userSearchString) => {
      const accessToken = await getAccessTokenAsync(instance, identity, [
        process.env.REACT_APP_SCOPES,
      ]);
      const url = searchEndpoint(userSearchString);
      const request = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      await fetch(url, request)
        .then((response) => response.json())
        .then((result) => {
          const employees = result.map((employee) => ({
            key: employee.id,
            text: `${employee.name} ${
              employee.employeeId ? employee.employeeId : ""
            }`,
            employeeNo: employee.employeeId,
            email: employee.email,
            iconProps: { iconName: "Contact" },
          }));
          setUserSearchResult(employees);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    if (userSearchString) searchEmployees(userSearchString);
  }, [userSearchString, identity, instance]);

  return (
    <Stack>
      <SearchBox
        ref={linkRef}
        placeholder="Sök efter användare"
        onChange={(e, newValue) => {
          setShowContextualMenu(true);
          newValue && debounceSetSearchString(newValue);
        }}
      />
      <ContextualMenu
        items={userSearchResult}
        hidden={!showContextualMenu}
        target={linkRef}
        onItemClick={(e, user) => {
          onItemClick(user);
        }}
        onDismiss={onHideContextualMenu}
        delayUpdateFocusOnHover={true}
        shouldFocusOnMount={false}
      />
    </Stack>
  );
}
