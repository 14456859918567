import { useMsal } from "@azure/msal-react";
import { useState, useEffect, useMemo } from "react";
import { getAccessTokenAsync } from "../api/msal";
import { getProfileAsync } from "../api/msgraph";
const useProfile = () => {
  const { instance, accounts } = useMsal();
  const [profile, setProfile] = useState();

  const identity = useMemo(
    () =>
      Array.isArray(accounts) && accounts.length ? accounts[0] : undefined,
    [accounts]
  );

  useEffect(() => {
    if (!(instance && identity)) {
      setProfile(undefined);
      return () => {};
    }

    const abortController = new AbortController();
    const asyncAction = async () => {
      const accessToken = await getAccessTokenAsync(instance, identity);
      accessToken &&
        (await getProfileAsync(accessToken, abortController.signal).then((p) =>
          setProfile(p)
        ));
    };
    asyncAction();
    return () => abortController?.abort();
  }, [instance, identity, setProfile]);

  return profile;
};
export default useProfile;
