import { mergeStyleSets } from '@fluentui/react'
import { theme } from './Theme'

export const globalClasses = mergeStyleSets({
  link: {
    color: theme.palette.themePrimary,
    textDecoration: 'none',
    backgroundColor: 'transparent',
    fontSize: '14px',

    ':hover': {
      color: theme.palette.themeDarkAlt,
      textDecoration: 'underline',
    },
  },
})
