import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import { fetchCropFormats } from "../api/profileImagesHandler";
import { MessagesContext } from "../context/MessagesContext";
import useMsGraphToken from "./useMsGraphToken";

const useCropFormats = () => {
    const scopes = useMemo(() => [process.env.REACT_APP_SCOPES], [])
    const accessToken = useMsGraphToken(scopes)
    const [cropFormats, setCropFormats] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const {addMessage} = useContext(MessagesContext)

    useEffect(() => {
      if(!accessToken) return;
      const abortController = new AbortController();
      setIsLoading(true)
      fetchCropFormats(
          abortController.signal,
          accessToken
        ).then((result) => {
          setCropFormats(result);
      }).catch((e) => {
        addMessage({
          type: "error",
          title: "Något gick fel när crop formaten skulle hämtas.",
          description: e.message,
        })
      }).finally(() => {
        setIsLoading(false)
      })
      return () => abortController.abort()
    }, [accessToken, addMessage])

    return [cropFormats, isLoading]
}

export default useCropFormats
