import {
  DirectionalHint,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { theme } from "../styles/Theme";
import PageLayout from "../components/PageLayout";
import { useMsal } from "@azure/msal-react";
import { AuthContext } from "../context/AuthContext";
import { stackTokens } from "../styles/stackConstants";
import TargetSystemCard from "../components/TargetSystemCard";
import { sendNewApproval } from "../api/profileImagesHandler";
import { MessagesContext } from "../context/MessagesContext";
import { useCallback } from "react";
import useEmployee from "../hooks/useEmployee";
import useTargetSystems from "../hooks/useTargetSystems";

const classes = mergeStyleSets({
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "25px",
  },
  card: {
    padding: "20px",
    background: theme.palette.white,
    border: `1px solid ${theme.palette.borderColor}`,
  },
});

export default function UserDetailsPage() {
  const { employeeNo } = useParams();
  const { instance } = useMsal();
  const { identity } = useContext(AuthContext);
  const {addMessage} = useContext(MessagesContext)

  
  let navigate = useNavigate();
  const [employee, employeeIsLoading] = useEmployee(employeeNo, addMessage);
  const [targetSystems, targetSystemsAreLoading] = useTargetSystems(employeeNo, addMessage)
  const [employeeStatusText, setEmployeeStatusText] = useState("");
  const [requiresApproval, setRequiresApproval] = useState(true)
  const [isSaving, setIsSaving] = useState(false);
  const [isShowingMaxRetriesMessage, setIsShowingMaxRetriesMessage] = useState(false)

  const calloutProps = { gapSpace: 0 };
  const buttonToolTipInfo =
    "För alla tillfällen som användaren vill ändra status för sitt godkännande. Kan tex användas för återkallande av godkännande";

  useEffect(() => {
    switch (employee?.approvalStatusId) {
      case 1:
        setEmployeeStatusText(
          "Vi inväntar användarens godkännande att använda den nya bilden publikt"
        );
        break;
      case 2:
        setEmployeeStatusText(
          "Användaren har godkänt att bild används publikt"
        );
        break;
      case 3:
        setEmployeeStatusText(
          "Användaren har avböjt att bild används publikt"
        );
        break;
      case 4:
        setEmployeeStatusText(
          "Användaren skall inte visas publikt"
        );
        setRequiresApproval(false)
        break;
      case 5:
        setEmployeeStatusText(
          "Användaren finns i systemet. Ingen begäran om godkännande har skickats ut"
        );
        break;
      case null:
        setEmployeeStatusText(
          "Användaren finns inte i systemet ännnu. Ingen begäran om godkännande har skickats ut"
        );
        break;
      default:
        break;
    }

  }, [employee]);

  const handleClick = () => {
    navigate(`/user/${employee.employeeNo}/edit`, { replace: false });
  };

  const handleApprovalButtonClick = async () => {
    setIsSaving(true);

    await sendNewApproval(
      null,
      instance,
      identity,
      employee.employeeNo
    );

    setIsSaving(false);
  };
  const handleTargetSystemCardOnMaxRetries = () => {
      if(isShowingMaxRetriesMessage) return;
      addMessage({
        type: "error",
        title: "Det tar lite längre tid än väntat...",
        description: "En eller flera av bilderna håller fortfarande på att behandlas. Titta gärna tillbaka lite senare. Under tiden så går det bra att jobba med någon annan persons bild."
      });
      setIsShowingMaxRetriesMessage(true);
  }

  if (employeeIsLoading || targetSystemsAreLoading) {
    return (
      <PageLayout>
        <Spinner label="Sidan laddas in" />
      </PageLayout>
    );
  }

  if (!employee || !targetSystems) {
    return (
      <PageLayout>
        <h1>Ett fel uppstod</h1>
      </PageLayout>
    );
  }
  return (
    <PageLayout>
      <Stack tokens={{ childrenGap: 40 }}>
        <Stack tokens={stackTokens} className={classes.card}>
          <Text variant="large">{employee.name}</Text>
          {isSaving && <Spinner />}
          <Text variant="medium">Status: {employeeStatusText}</Text>
          {requiresApproval && <>
            <Stack tokens={stackTokens} horizontal>
              <StackItem>
                <TooltipHost
                  content={buttonToolTipInfo}
                  id={"tooltip"}
                  calloutProps={calloutProps}
                  styles={{ root: { display: "inline-block" } }}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <PrimaryButton
                    text="Begär nytt godkännande"
                    onClick={handleApprovalButtonClick}
                    disabled={employee.approvalStatusId === null || employee.approvalStatusId === 5}
                  />
                </TooltipHost>
              </StackItem>
            </Stack>
          </>}
        </Stack>
        <Stack tokens={{ childrenGap: 40 }}>
          <Text variant="xLarge">Befintliga format:</Text>
          <div className={classes.imageContainer}>
            {targetSystems.map((targetSystem, i) => (
              <TargetSystemCard
                key={targetSystem.id}
                targetSystem={targetSystem}
                employeeNo={employeeNo}
                onMaxRetries={handleTargetSystemCardOnMaxRetries}
              />
            ))}
          </div>
          <StackItem>
            <PrimaryButton
              text="Redigera/Ladda upp bild"
              onClick={handleClick}
            />
          </StackItem>
        </Stack>
      </Stack>
    </PageLayout>
  );
}
