import {
  DetailsList,
  DetailsRow,
  mergeStyleSets,
  NeutralColors,
  Persona,
  PersonaSize,
  ScrollablePane,
  ScrollbarVisibility,
  SelectionMode,
  Sticky,
  StickyPositionType,
} from "@fluentui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const classes = mergeStyleSets({
  tableWrapper: {
    border: `1px solid ${NeutralColors.gray30}`,
    height: "100%",
    width: "100%",
    position: "relative",
  },
});

export default function EmployeesTable({ items, setItems }) {
  let navigate = useNavigate();
  const [columns, setColumns] = useState([
    {
      key: 1,
      name: "Anst.nummer",
      fieldName: "employeeNo",
      minWidth: 50,
      maxWidth: 100,
      isSortedDescending: false,
    },
    {
      key: 2,
      name: "Namn",
      fieldName: "name",
      minWidth: 100,
      maxWidth: 200,
      isSortedDescending: false,
      onRender: (item) => {
        return <Persona text={item.name} size={PersonaSize.size8} />;
      },
    },
    {
      key: 3,
      name: "Skickad",
      fieldName: "sent",
      minWidth: 100,
      maxWidth: 200,
      isSortedDescending: false,
    },
    {
      key: 4,
      name: "Skapad",
      fieldName: "created",
      minWidth: 100,
      maxWidth: 200,
      isSortedDescending: false,
    },
  ]);

  const onColumnClick = (e, column) => {
    let isSortedDescending = column.isSortedDescending;

    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }

    const sorted = items.sort((a, b) => {
      const columnFieldNameA = a[column.fieldName];
      const columnFieldNameB = b[column.fieldName];

      if (columnFieldNameA === undefined) {
        return 1;
      }

      if (columnFieldNameB === undefined) {
        return -1;
      }

      if (columnFieldNameA > columnFieldNameB) {
        return 1;
      } else if (columnFieldNameA < columnFieldNameB) {
        return -1;
      }
      return 0;
    });

    if (isSortedDescending) {
      setItems(sorted.reverse());
    } else {
      setItems(sorted);
    }

    setColumns(
      columns.map((col) => {
        col.isSorted = col.key === column.key;
        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }
        return col;
      })
    );
  };

  const onRenderRow = (props) => {
    const customStyles = {
      root: { "border-bottom": `1px solid ${NeutralColors.gray30}` },
    };

    return (
      <DetailsRow
        {...props}
        styles={customStyles}
        onClick={() => onItemInvoked(props.item)}
      />
    );
  };

  const onItemInvoked = (item) => {
    navigate(`/user/${item.employeeNo}`, { replace: false });
  };

  return (
    <div className={classes.tableWrapper}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsList
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
          onRenderRow={onRenderRow}
          onColumnHeaderClick={onColumnClick}
          getKey={(e, item) => item.fieldName}
          onRenderDetailsHeader={(headerProps, defaultRender) => {
            return (
              <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
              >
                <div>{defaultRender(headerProps)}</div>
              </Sticky>
            );
          }}
        />
      </ScrollablePane>
    </div>
  );
}
