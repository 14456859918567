import { MessageBar, MessageBarType } from "@fluentui/react";
import PageLayout from "../components/PageLayout";

const UnauthorizedPage = () => {
  return (
    <PageLayout>
      <MessageBar isMultiline={false} messageBarType={MessageBarType.warning}>
        Du saknar behörighet till sidan du försökte besöka. Kontakta IT för att
        få rätt behörighet.
      </MessageBar>
    </PageLayout>
  );
};

export default UnauthorizedPage;
