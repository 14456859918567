export const getProfileAsync = async (accessToken, signal = null) => {
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers,
  }

  if (signal) options.signal = signal

  // https://docs.microsoft.com/en-us/previous-versions/azure/ad/graph/api/entity-and-complex-type-reference#user-entity
  return fetch(
    'https://graph.microsoft.com/v1.0/me?$select=displayName,mail,jobTitle,preferredLanguage,employeeId,country,city,department,userType',
    options
  )
    .then(response => response.json())
    .catch(console.log)
}

export const getProfilePhotoAsync = async (accessToken, signal = null) => {
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers,
  }

  if (signal) options.signal = signal

  return fetch('https://graph.microsoft.com/v1.0/me/photos/96x96/$value', options)
    .then(response => {
      return response.blob()
    })
    .then(blob => {
      const url = URL.createObjectURL(blob)
      return url
    })
    .catch(console.log)
}
