import { mergeStyleSets } from "@fluentui/react";
import { theme } from "../styles/Theme";

const classes = mergeStyleSets({
  layout: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    zIndex: 1,
    position: "sticky",
    top: 0,
  },
  main: {
    flex: 1,
    overflow: "auto",
    background: theme.palette.mainBackgroundColor,
  },
});

const AppLayout = ({ children, header }) => {
  return (
    <div className={classes.layout}>
      <div className={classes.header}>{header}</div>
      <main className={classes.main}>{children}</main>
    </div>
  );
};

export default AppLayout;
