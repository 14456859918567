import { msalConfig } from "../config";
const { scopes } = msalConfig;

export const getAccessTokenAsync = async (
  msalInstance,
  identity,
  scopes = msalConfig.scopes
) => {
  const request = {
    account: identity,
    scopes,
  };
  let accessToken = null;
  try {
    accessToken = await msalInstance
      .acquireTokenSilent(request)
      .then((response) => response.accessToken);
  } catch (e) {
    accessToken = await msalInstance
      .acquireTokenRedirect(request)
      .then((response) => response.accessToken);
  }
  return accessToken;
};

export const signInAsync = (msalInstance) => {
  const request = {
    scopes,
  };
  msalInstance.loginRedirect(request).catch((e) => {
    console.error(e);
  });
};

export const signOutAsync = (msalInstance) => {
  msalInstance.logoutRedirect().catch((e) => {
    console.error(e);
  });
};
