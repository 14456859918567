export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_APP_TENNANT_ID}`,
    redirectUri: process.env.REACT_APP_AZURE_APP_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  scopes: ["User.Read"],
};

export const loginRequest = (identity) => {
  return {
    account: identity,
    scopes: ["User.Read"],
  };
};

export const apiRequest = (identity) => {
  return {
    account: identity,
    scopes: [process.env.REACT_APP_SCOPES],
  };
};
