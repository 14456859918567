//Employee
export const employeesEndpoint = `${process.env.REACT_APP_SERVER_URI}/Employees/WithPendingApprovals`;

export const searchEndpoint = (userSearchString) =>
  `${process.env.REACT_APP_SERVER_URI}/Employees/Search/${userSearchString}`;

export const employeeByEmployeeNoEndpoint = (employeeNo) =>
  `${process.env.REACT_APP_SERVER_URI}/Employees/ByEmployeeNo/${employeeNo}`;

export const employeeFromGraphEndpoint = (userId) =>
  `${process.env.REACT_APP_SERVER_URI}/Employees/FromGraphById/${userId}`;

export const approvalEndpoint = (employeeNo, approvalStatusId) =>
  `${process.env.REACT_APP_SERVER_URI}/Employees/Approval/${employeeNo}/${approvalStatusId}`;

export const approvalUpdateEndpoint = (employeeNo) =>
  `${process.env.REACT_APP_SERVER_URI}/Employees/SendApprovalUpdate/${employeeNo}`;

//Application
export const targetSystemsEndpoint = `${process.env.REACT_APP_SERVER_URI}/Application/TargetSystems`;
export const cropFormatsEndpoint = `${process.env.REACT_APP_SERVER_URI}/Application/CropFormats`;

export const pendingJobCountEndpoint = (employeeNo, cropFormatId) =>
  `${process.env.REACT_APP_SERVER_URI}/Application/PendingJobCount/${employeeNo}/${cropFormatId}`;

//Profileimages
export const focalPointEndpoint = (employeeNo) =>
  `${process.env.REACT_APP_SERVER_URI}/ProfileImage/FocalPoint/${employeeNo}`;

export const cropDataEndpoint = (employeeNo) =>
  `${process.env.REACT_APP_SERVER_URI}/ProfileImage/CropData/${employeeNo}`;

export const originalProfileImageEndpoint = (employeeNo) =>
  `${process.env.REACT_APP_SERVER_URI}/ProfileImage/OriginalProfileImage/${employeeNo}`;
