import { useMsal } from "@azure/msal-react";
import { useState, useEffect, useMemo } from "react";
import { getAccessTokenAsync } from "../api/msal";
import { getProfilePhotoAsync } from "../api/msgraph";
const useProfilePhoto = () => {
  const { instance, accounts } = useMsal();
  const [profilePhoto, setProfilePhoto] = useState();

  const identity = useMemo(
    () =>
      Array.isArray(accounts) && accounts.length ? accounts[0] : undefined,
    [accounts]
  );

  useEffect(() => {
    if (!(instance && identity)) {
      setProfilePhoto(undefined);
      return () => {};
    }

    const abortController = new AbortController();
    const asyncAction = async () => {
      const accessToken = await getAccessTokenAsync(instance, identity);
      accessToken &&
        (await getProfilePhotoAsync(accessToken, abortController.signal).then(
          (p) => setProfilePhoto(p)
        ));
    };
    asyncAction();
    return () => abortController?.abort();
  }, [instance, identity, setProfilePhoto]);

  return profilePhoto;
};
export default useProfilePhoto;
