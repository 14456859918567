import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import ProfileButton from "./ProfileButton";
import { SignInButton } from "./SignInButton";

const MeButton = () => {
  return (
    <>
      <AuthenticatedTemplate>
        <ProfileButton />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignInButton />
      </UnauthenticatedTemplate>
    </>
  );
};

export default MeButton;
