import { useEffect, useMemo } from "react";
import { useContext } from "react";
import { useState } from "react";
import { fetchTargetSystems } from "../api/profileImagesHandler";
import { MessagesContext } from "../context/MessagesContext";
import useMsGraphToken from "./useMsGraphToken";

const useTargetSystems = (employeeNo) => {
  const scopes = useMemo(() => [process.env.REACT_APP_SCOPES], [])
  const accessToken = useMsGraphToken(scopes)
  const [targetSystems, setTargetSystems] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const {addMessage} = useContext(MessagesContext)

  useEffect(() => {
    if (!accessToken) return;
    const abortController = new AbortController();
    setIsLoading(true)
    fetchTargetSystems(
        abortController.signal,
        accessToken,
        employeeNo,
      ).then((result) => {
        if (result !== null) {
            setTargetSystems(result);
        }
      }).catch((e) => {
        addMessage({
            type: "error",
            title: "Unable to load target systems",
            description: e.message
        })
      }).finally(() => {
        setIsLoading(false)
      })
    return () => abortController.abort()
  }, [employeeNo, accessToken, addMessage])

  return [targetSystems, isLoading]
}

export default useTargetSystems