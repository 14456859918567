import { mergeStyleSets } from "@fluentui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import { ProtectedContent } from "../ProtectedContent";
import { theme } from "../../styles/Theme";

const classes = mergeStyleSets({
  items: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    display: "flex",
  },

  link: {
    textDecoration: "none",
    color: theme.palette.headerTextColor,
    padding: "17px",
    selectors: {
      ":hover": {
        backgroundColor: "hsla(0,0%,100%,.08)",
      },
    },
  },

  active: {
    backgroundColor: "hsla(0,0%,100%,.08)",
    textDecoration: "none",
    padding: "17px",
    color: theme.palette.headerTextColor,
  },
});

export default function HorizontalNavigation({ links }) {
  return (
    <nav>
      <ul className={classes.items}>
        {links?.map((link, i) => (
          <ProtectedContent
            isProtected={link.isProtected}
            roles={link.roles}
            key={i}
          >
            <li style={theme.fonts.medium}>
              <NavLink
                to={`/${link.to}`}
                title={link.description || link.name || link.to}
                className={({ isActive }) =>
                  !isActive ? classes.link : classes.active
                }
              >
                {link.name || link.to}
              </NavLink>
            </li>
          </ProtectedContent>
        ))}
      </ul>
    </nav>
  );
}
