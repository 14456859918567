import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import { fetchFocalPoint } from "../api/profileImagesHandler";
import { MessagesContext } from "../context/MessagesContext";
import useMsGraphToken from "./useMsGraphToken";

const useFocalPoint = (employeeNo, defaultValue) => {
    const scopes = useMemo(() => [process.env.REACT_APP_SCOPES], [])
    const accessToken = useMsGraphToken(scopes)
    const [focalPoint, setFocalPoint] = useState(defaultValue)
    const [isLoading, setIsLoading] = useState(false)
    const {addMessage} = useContext(MessagesContext)

    useEffect(() => {
      if(!accessToken) return;
      const abortController = new AbortController();
      setIsLoading(true)
      fetchFocalPoint(
          abortController.signal,
          accessToken,
          employeeNo
        ).then((result) => {
          if (result !== null) {
            setFocalPoint(result);
          }
        }).catch((e) => {
          addMessage({
            type: "error",
            title: "Något gick fel när fokuspunkten skulle hämtas.",
            description: e.message,
          })
        }).finally(() => {
          setIsLoading(false)
        })
      return () => abortController.abort()
    }, [employeeNo, accessToken, addMessage])

    return [focalPoint, isLoading]
}

export default useFocalPoint