import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useContext } from "react";
import { fetchCropData } from "../api/profileImagesHandler";
import { MessagesContext } from "../context/MessagesContext";
import useMsGraphToken from "./useMsGraphToken";

const useCropData = (employeeNo) => {
    const scopes = useMemo(() => [process.env.REACT_APP_SCOPES], [])
    const accessToken = useMsGraphToken(scopes)
    const [cropData, setCropData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const {addMessage} = useContext(MessagesContext)


    useEffect(() => {
      if(!accessToken) return;
      const abortController = new AbortController();
      setIsLoading(true)
      fetchCropData(
        abortController.signal,
        accessToken,
        employeeNo
      ).then((result) => {
        if (result !== null) {
            const map = result.reduce((acc, current) => {
                acc[current.cropFormatId] = current
                return acc
            }, {})
            setCropData(map);
        }
      }).catch((e) => {
        addMessage({
          type: "error",
          title: "Något gick fel när crop data skulle hämtas.",
          description: e.message,
        })
      }).finally(() => {
        setIsLoading(false)
      })
      return () => abortController.abort()
    }, [employeeNo, accessToken, addMessage])

    return [cropData, isLoading]
}

export default useCropData