import React, { useLayoutEffect } from "react";
import {
  getInitials,
  mergeStyleSets,
  Persona,
  PersonaSize,
  Stack,
  StackItem,
} from "@fluentui/react";
import { theme } from "../styles/Theme";
import { SignOutButton } from "./Buttons/SignOutButton";

const classes = mergeStyleSets({
  profileCard: {
    position: "absolute",
    minWidth: "20rem",
    boxShadow: "0 24px 54px rgb(0 0 0 / 15%), 0 4.5px 13.5px rgb(0 0 0 / 8%)",
    zIndex: 1000000,
    backgroundColor: theme.palette.white,
    right: 0,
  },
});

export default function UserProfileCard({
  userName,
  userEmail,
  userProfilePhoto,
  close,
}) {
  useLayoutEffect(() => {
    const eventHandler = () => close();
    // TODO: Find out if this is kosher :-)
    setTimeout(() => window.addEventListener("click", eventHandler), 0);
    return () => {
      window.removeEventListener("click", eventHandler);
    };
  }, [close]);

  const persona = {
    imageUrl: userProfilePhoto,
    imageInitials: getInitials(userName),
    text: userName,
    secondaryText: userEmail,
    imageAlt: userName,
  };

  return (
    <div className={classes.profileCard}>
      <Stack>
        <StackItem align={"end"}>
          <SignOutButton style={{ padding: "1rem" }} />
        </StackItem>
        <StackItem style={{ padding: "0 0 1rem 1rem" }}>
          <Persona {...persona} size={PersonaSize.size100} />
        </StackItem>
      </Stack>
    </div>
  );
}
