export const stackTokens = { childrenGap: 10 };
export const stackTokensSmaller = { childrenGap: 6 };
export const stackTokensLarge = { childrenGap: 16 };
export const stackTokensSmall = { childrenGap: 5 };

export const stackStyles = {
  root: {
    width: '320px',
  },
};

export const stackMarginTop = {
  root: {
    marginTop: '16px',
  },
};

export const stackMarginBottom = {
  root: {
    marginBottom: '16px',
  },
};

export const stackWithPaddingStyles = {
  root: {
    padding: '16px',
  },
};

export const outerStackStyles = {
  root: {
    width: '100%',
  },
};

export const stackItem60 = {
  root: {
    width: '60%',
  },
};
export const stackItem40 = {
  root: {
    width: '40%',
  },
};

export const stackFullWidth = {
  root: {
    width: '100%',
  },
};
